import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/51m0bXm-EU4">
    <SEO title="From the Palace to the Prairie - Living in Babylon" />
  </Layout>
)

export default SermonPost
